import React from 'react';
import { Card, Typography, CardContent, Stack } from '@mui/material';
import { Link } from 'gatsby-material-ui-components';

import Logo from '@gaia/components/Logo';

interface Link {
    url: string;
    label: string;
}

interface MediaKitCardParams {
    title: string;
    subtitle: string;
    links: Link[];
}

const MediaKitCard = ({ title, subtitle, links }: MediaKitCardParams) => {
    return (
        <Card
            variant="outlined"
            sx={{
                borderColor: 'primary.main',
                maxWidth: '368px',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <CardContent
                sx={{
                    backgroundColor: 'primary.main',
                    textAlign: 'center',
                    pt: 9,
                    pb: 3,
                }}
            >
                <Logo color="white" variant="iconOnly" />
                <Typography color="white" variant="h4" sx={{ pt: 3, pb: 2 }}>
                    {title}
                </Typography>
                <Typography color="white" variant="body1">
                    {subtitle}
                </Typography>
            </CardContent>
            <Stack
                sx={{
                    padding: 4,
                    gap: 1,
                    flexGrow: 1,
                    justifyContent: 'center',
                }}
            >
                {links.map(link => (
                    <Link href={link.url} target="_blank" rel="noopener noreferrer">
                        {link.label}
                    </Link>
                ))}
            </Stack>
        </Card>
    );
};

export default MediaKitCard;
