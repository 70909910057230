import React from 'react';
import { Typography, Box, Stack, Divider, List } from '@mui/material';
import { Button } from 'gatsby-material-ui-components';

import Layout from '@gaia/Layout';
import ListItem from '@gaia/components/ListItem';

import SEO from '../components/seo';
import MediaKitCard from '../components/MediaKit/MediaKitCard';

const mediaSections = [
    {
        title: 'Brand Assets',
        subtitle: 'Discover essential materials for your media needs like our logo kit.',
        links: [
            {
                label: 'Earth Hero Logo Kit',
                url: 'https://www.earthhero.org/media-kit/Earth_Hero_Logo_Kit.zip',
            },
            {
                label: 'Earth Hero One Page Posters',
                url: 'https://www.earthhero.org/media-kit/Earth_Hero_One_Page_Posters.zip',
            },
        ],
    },
    {
        title: 'Press Releases',
        subtitle: 'Explore essential information through our official press releases.',
        links: [
            {
                label: 'Download New IPCC Report',
                url:
                    'https://www.earthhero.org/media-kit/NewIPCCReport-DamageisMountingYetNotTooLate.pdf',
            },
            {
                label: 'Download Earth Month - We Are At The Brink',
                url: 'https://www.earthhero.org/media-kit/EarthMonth-WeAreattheBrink.pdf',
            },
        ],
    },
    {
        title: 'Media Coverage',
        subtitle: 'Access media coverage about the Earth Hero Climate Change app.',
        links: [
            {
                label: 'Apple Earth Day Feature article',
                url: 'https://apps.apple.com/us/story/id1613364866',
            },
            {
                label: 'The Gaurdian article',
                url:
                    'https://www.theguardian.com/commentisfree/2022/apr/06/climate-scientists-are-desperate-were-crying-begging-and-getting-arrested',
            },
            {
                label: 'Wired article',
                url: 'https://www.wired.com/story/actions-you-can-take-to-tackle-climate-change/',
            },
            {
                label: 'Travel Tomorrow article ',
                url:
                    'https://traveltomorrow.com/5-apps-that-can-help-you-track-your-carbon-footprint/',
            },
            {
                label: 'Make Use Of article',
                url: 'https://www.makeuseof.com/tracking-carbon-footprint-best-apps/',
            },
            {
                label: 'G7 Platform for Net-zero and Well-being in Life',
                url: 'https://www.g7life.org/stakeholders/46/',
            },
        ],
    },
];

const MediaKit = () => {
    return (
        <Layout>
            <SEO title="Media Kit" />
            <Stack
                component="section"
                sx={{ mb: { xs: 5, sm: 11 }, flexDirection: { md: 'row' }, gap: { xs: 4, md: 7 } }}
            >
                <Box flexBasis="55%">
                    <Typography variant="h1" sx={{ color: 'primary.main', mb: { xs: 2, md: 4 } }}>
                        Press & Media
                    </Typography>
                    <Typography variant="subtitle1" sx={{ mb: { xs: 2, md: 4 } }}>
                        We created the Earth Hero app to serve as a personal guide for people who
                        want to be a part of the solution.
                    </Typography>
                    <Typography variant="body1" sx={{ mb: { xs: 2, md: 4 } }}>
                        Join the Earth Hero community - Try the app and help restore a healthy Earth
                        where all lives can thrive.
                    </Typography>
                    <Button
                        to="/"
                        variant="contained"
                        color="primary"
                        sx={{ size: { xs: 'medium', md: 'large' } }}
                    >
                        TRY EARTH HERO APP
                    </Button>
                </Box>
                <Box flexBasis="45%">
                    <Typography variant="h1" sx={{ color: 'primary.main', mb: -4 }}>
                        “
                    </Typography>
                    <Typography variant="subtitle1" sx={{ color: 'primary.main', mb: 2 }}>
                        A few years ago my significant other heard a story in the news about the
                        climate crisis and wanted to be part of the solution. She searched for a
                        climate action app, but could not find one. Because I knew how to code apps,
                        she asked if I could help.
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        I accepted the challenge and reached out to Dr. Peter Kalmus, and Dr.
                        Annette Olson - scientists working on climate change - and we agreed to form
                        a team of volunteers to create the app. It’s been an amazing journey.
                    </Typography>
                    <Typography variant="caption" sx={{ mb: 2 }}>
                        -Ben Gerhold, Co-Founder and President of The Board of Directors.
                    </Typography>
                </Box>
            </Stack>
            <Divider />
            <Stack
                component="section"
                alignItems="flex-start"
                sx={{ flexDirection: { md: 'row' }, gap: { md: 3 }, my: { xs: 5, md: 11 } }}
            >
                <List sx={{ flexBasis: '25%', py: { xs: 0, md: 1 } }}>
                    <ListItem text="Founded in 2019" />
                    <ListItem text="Over 100,000 users across 150 countries" />
                    <ListItem text="Over 500,000 climate actions taken" />
                </List>
                <List sx={{ flexBasis: '25%', py: { xs: 0, md: 1 } }}>
                    <ListItem text="Powered by a community of over 100 volunteers including leading scientists, developers, designers, translators, and communicators across 25+ countries" />
                </List>
                <List sx={{ flexBasis: '25%', py: { xs: 0, md: 1 } }}>
                    <ListItem text="Each suggested action is thoroughly researched and supported by documented scientific data" />
                </List>
                <List sx={{ flexBasis: '25%', py: { xs: 0, md: 1 } }}>
                    <ListItem text="The app offers hundreds of actions people can take immediately to reduce carbon pollution, improve their lives, and help influence systemic changes to build a better world" />
                </List>
            </Stack>
            <Stack
                component="section"
                justifyContent="center"
                sx={{
                    flexDirection: { md: 'row' },
                    gap: 3,
                    alignItems: { xs: 'center', md: 'stretch' },
                }}
            >
                {mediaSections.map(section => (
                    <Box flexBasis="33%" display="flex">
                        <MediaKitCard
                            title={section.title}
                            subtitle={section.subtitle}
                            links={section.links}
                        />
                    </Box>
                ))}
            </Stack>
        </Layout>
    );
};

export default MediaKit;
