import React from 'react';
import { ListItem as MuiListItem, Icon, ListItemText } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';

interface ListItemParams {
    text: string;
}

const ListItem = ({ text }: ListItemParams) => {
    return (
        <MuiListItem sx={{ py: 1, px: 0 }}>
            <Icon sx={{ color: 'primary.main', mr: 2, mt: 0.75, alignSelf: 'flex-start' }}>
                <ArrowForward />
            </Icon>
            <ListItemText primary={text} />
        </MuiListItem>
    );
};

export default ListItem;
